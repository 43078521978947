import {
  type CSSProperties,
  forwardRef,
  memo,
  type MouseEventHandler
} from 'react'

import { cva, cx } from 'class-variance-authority'
import { useLocation } from 'react-router-dom'

import { setAvailablePersonsCount } from '@/entities/take-table'
import {
  selectTimelineTable,
  setTableWithOpenTimeline,
  unselectTimelineTable
} from '@/entities/timeline'
import { useCSSImageLoading } from '@/shared/hooks'
import { PATHS } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'

import { MapModeEnum } from '../../model/mapModes'
import {
  selectMapMode,
  selectSelectedTables,
  selectShowDeposits,
  selectTableToChangeTo
} from '../../model/selectors'
import { selectTable, type TableTime, unselectTable } from '../../model/slice'
import { TableStatusEnum } from '../../model/tableStatus'
import { MapStatusPopover } from '../../ui/MapStatusPopover/MapStatusPopover'

import css from './MapItem.module.css'

function getDepositColor(
  deposits: DepositWithColors[],
  deposit: Deposit | null
) {
  if (!deposit) {
    return deposits[0].backgroundColor
  }

  return deposits.find(
    (depositWithColor) => depositWithColor.value === deposit.value
  )?.backgroundColor
}

interface MapItemProps {
  mapIdx?: number
  selectedTableId?: number
  table: ConfItem
  tablesElements: HTMLDivElement[]
  isNoInfo: boolean
  tableTime?: TableTime
  clickableDisabled?: boolean
  onlyView: boolean
  deposits?: DepositWithColors[]
  zoomTo?: string
  isPickTable?: boolean
  withDepositsInfo?: boolean
  withComingSoon?: boolean
  withRecentlyCompleted?: boolean
  renderTransferTablePopup?: (props: {
    clickHandler: MouseEventHandler
    renderTable: () => JSX.Element
    renderTableClickable: (isCurrentReservation: boolean) => JSX.Element
    tableId: number
  }) => JSX.Element
}

const mapItemStyle = cva(css.item__table, {
  variants: {
    isDisabled: {
      true: css.item__table_disabled,
      false: ''
    },
    isSelected: {
      true: css.item__table_selected,
      false: ''
    },
    isComingSoon: {
      true: css.item__table_soon,
      false: ''
    },
    isRecentlyCompleted: {
      true: css.item__table_recently,
      false: ''
    },
    isChanged: {
      true: css.item__table_changed,
      false: ''
    },
    isInProgressEnds: {
      true: css.item__table_progress_ends,
      false: ''
    }
  },
  defaultVariants: {
    isDisabled: false,
    isSelected: false
  }
})

const MapItem = forwardRef<HTMLDivElement, MapItemProps>(
  (
    {
      mapIdx,
      selectedTableId,
      table,
      tablesElements,
      tableTime,
      isNoInfo,
      onlyView,
      zoomTo,
      isPickTable,
      clickableDisabled = true,
      withDepositsInfo = false,
      withComingSoon = true,
      withRecentlyCompleted = false,
      deposits = [],
      renderTransferTablePopup
    },
    ref
  ) => {
    const { pathname } = useLocation()
    const dispatch = useAppDispatch()
    const mapMode = useAppSelector(selectMapMode)
    const selectedTables = useAppSelector(selectSelectedTables)
    const tableToChangeTo = useAppSelector(selectTableToChangeTo)
    const showDeposits = useAppSelector(selectShowDeposits)

    const {
      layer,
      is_free,
      x_coordinate,
      y_coordinate,
      width,
      height,
      direction,
      map_item
    } = table

    const {
      id,
      item_number,
      item,
      type,
      min_persons_count,
      max_persons_count,
      resize,
      deposit
    } = map_item
    const { image } = item

    const isCurrentSelected =
      Boolean(selectedTables.find((table) => table.id === id)) ||
      id === selectedTableId

    const isCurrentChangeToSelected = Boolean(
      tableToChangeTo.find((table) => table.id === id)
    )

    const { isImgLoaded } = useCSSImageLoading(image)

    const isEmpty = is_free !== undefined ? is_free : true
    const isTable = type === 'place'
    const isFreeTable = isTable && isEmpty

    const isInProgress =
      tableTime?.status === TableStatusEnum.inProgress &&
      !renderTransferTablePopup
    const isInProgressEnds =
      tableTime?.status === TableStatusEnum.inProgressEnds &&
      !renderTransferTablePopup
    const isComingSoon =
      tableTime?.status === TableStatusEnum.comingSoon &&
      withComingSoon &&
      !renderTransferTablePopup
    const isRecentlyCompleted =
      tableTime?.status === TableStatusEnum.recentlyCompleted &&
      withRecentlyCompleted

    const isDisabled =
      (!isFreeTable || isInProgress || isInProgressEnds) && !isCurrentSelected

    const isSelected = isCurrentSelected || isCurrentChangeToSelected

    const isClickable =
      isTable && !(!clickableDisabled && isDisabled) && !onlyView

    const isDeposit =
      isFreeTable &&
      !isSelected &&
      deposits.length > 0 &&
      showDeposits &&
      withDepositsInfo

    const clickHandler: MouseEventHandler<
      HTMLDivElement | HTMLButtonElement
    > = (e) => {
      if ((!clickableDisabled && isDisabled) || onlyView) return
      e.stopPropagation()

      if (
        (pathname === PATHS.map || pathname === PATHS.waitingList) &&
        !isPickTable
      ) {
        dispatch(setTableWithOpenTimeline(map_item))
      }

      const isMainTable = selectedTables[0]?.id === id

      if (isCurrentSelected) {
        if (mapMode === MapModeEnum.one) {
          dispatch(unselectTimelineTable())
        }
        dispatch(unselectTable(map_item))
        if (!isMainTable && mapMode === MapModeEnum.change) {
          dispatch(selectTable(map_item))
        }
      } else {
        dispatch(selectTable(map_item))
        dispatch(
          setAvailablePersonsCount({
            min: min_persons_count,
            max: max_persons_count
          })
        )
        if (mapMode === MapModeEnum.one) {
          dispatch(selectTimelineTable(id))
        }
      }
    }

    const tableStyles: CSSProperties = {
      WebkitMask: `url(${image}) no-repeat 50% 50%`,
      mask: `url(${image}) no-repeat 50% 50%`,
      maskSize: 'cover',
      WebkitMaskSize: 'cover',
      width,
      height,
      backgroundColor: isDeposit
        ? getDepositColor(deposits, deposit)
        : undefined
    }

    const otherObjStyles: CSSProperties = {
      background: `url(${image})`,
      backgroundSize: resize === 'block' ? 'cover' : 'contain',
      backgroundRepeat: 'no-repeat',
      width,
      height
    }

    const mapItemContent = (
      <div
        data-id={id}
        ref={ref}
        id={zoomTo ? `${mapIdx ?? ''}${id}` : undefined}
        className={cx(css.item, { [css.item_hidden]: !isImgLoaded })}
        style={{
          transform: `translate(${x_coordinate}px,${y_coordinate}px) rotate(${direction}deg)`,
          transformOrigin: '0% 0%',
          zIndex: layer,
          pointerEvents: isClickable ? 'auto' : 'none',
          cursor: isTable ? 'pointer' : 'auto'
        }}
      >
        {renderTransferTablePopup && !isDisabled ? (
          renderTransferTablePopup({
            renderTable: () => (
              <div
                data-vaul-no-drag
                className={mapItemStyle({
                  isInProgressEnds,
                  isDisabled,
                  isSelected: false,
                  isComingSoon,
                  isChanged: isSelected
                })}
                style={isTable ? tableStyles : otherObjStyles}
              />
            ),
            renderTableClickable: (isCurrentReservation: boolean) => (
              <div
                data-vaul-no-drag
                className={mapItemStyle({
                  isInProgressEnds,
                  isDisabled,
                  isSelected: isCurrentReservation,
                  isComingSoon,
                  isChanged: isSelected && !isCurrentReservation
                })}
                style={isTable ? tableStyles : otherObjStyles}
                onClick={clickHandler}
              />
            ),
            clickHandler,
            tableId: id
          })
        ) : (
          <div
            data-vaul-no-drag
            className={mapItemStyle({
              isInProgressEnds,
              isDisabled,
              isSelected,
              isComingSoon,
              isRecentlyCompleted
            })}
            style={isTable ? tableStyles : otherObjStyles}
            onClick={clickHandler}
          />
        )}
        <div
          className={css.item__info}
          style={{
            rotate: `${-direction}deg`,
            transformOrigin: '0% 0%'
          }}
        >
          <span
            className={cx(css.item__number, {
              [css.item__number_available]:
                isSelected || isDeposit || isRecentlyCompleted,
              [css.item__number_soon]: isComingSoon,
              [css.item__number_disabled]: isDisabled
            })}
          >
            {item_number}
          </span>
        </div>
      </div>
    )

    return (
      <MapStatusPopover
        table={table}
        isNoInfo={isNoInfo}
        tablesElements={tablesElements}
        tableTime={tableTime}
      >
        {mapItemContent}
      </MapStatusPopover>
    )
  }
)

export default memo(MapItem)
