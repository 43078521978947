import { baseApi, tagTypes } from '@/shared/api'

const { SCHEDULE_EXCEPTIONS_TAG } = tagTypes

export const scheduleApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    scheduleExceptions: build.query<ScheduleException[], { addressId: number }>(
      {
        query: ({ addressId }) => ({
          url: `/admin/addresses/${addressId}/exceptions/`
        }),
        providesTags: [SCHEDULE_EXCEPTIONS_TAG]
      }
    )
  })
})

export const { useScheduleExceptionsQuery } = scheduleApi
