/* eslint-disable import/no-duplicates */
import { useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'
import { cx } from 'class-variance-authority'
import {
  addMonths,
  addWeeks,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  isSameMonth,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek
} from 'date-fns'
import { ru } from 'date-fns/locale'

import { selectSelectedHall } from '@/entities/halls'
import { useScheduleExceptionsQuery } from '@/entities/schedule'
import { selectSelectedAddressId } from '@/entities/session'
import { nowWithTimezone } from '@/shared/lib'
import { useAppSelector } from '@/shared/model'
import { Button, Icon, Label } from '@/shared/ui'

import css from './ReservationDatePicker.module.css'

type ReservationDatePickerProps = {
  isMonth?: boolean
  selectedDate?: Date
  selectDateHandler: (date: Date) => void
  isInProgress?: boolean
}

export function ReservationDatePicker({
  isMonth = false,
  selectedDate,
  isInProgress = false,
  selectDateHandler
}: ReservationDatePickerProps) {
  const [page, setPage] = useState(0)
  const addressId = useAppSelector(selectSelectedAddressId)
  const hallId = useAppSelector(selectSelectedHall)

  const { data: exceptions } = useScheduleExceptionsQuery(
    addressId ? { addressId } : skipToken
  )

  const hallExceptions = exceptions?.filter(
    (e) => hallId && e.halls.includes(hallId)
  )

  const closeHallExceptions = hallExceptions?.filter((e) => e.type === 'close')
  const openHallExceptions = hallExceptions?.filter(
    (e) => e.type === 'extension'
  )

  const week = addWeeks(nowWithTimezone(), page)
  const weekInterval = eachDayOfInterval({
    start: startOfWeek(week, { weekStartsOn: 1 }),
    end: endOfWeek(week, { weekStartsOn: 1 })
  })
  const month = addMonths(nowWithTimezone(), page)
  const monthInterval = eachDayOfInterval({
    start: startOfWeek(startOfMonth(month), { weekStartsOn: 1 }),
    end: endOfWeek(endOfMonth(month), { weekStartsOn: 1 })
  })

  const nextWeek = () => {
    setPage((prev) => prev + 1)
  }

  const prevWeek = () => {
    setPage((prev) => prev - 1)
  }

  const renderMonth = () => {
    const firstMonth = weekInterval[0].getMonth()
    const lastMonth = weekInterval[weekInterval.length - 1].getMonth()
    const firstYear = weekInterval[0].getFullYear()
    const lastYear = weekInterval[weekInterval.length - 1].getFullYear()

    if (firstYear !== lastYear) {
      return `${format(weekInterval[0], 'LLLL yyyy', { locale: ru })} -
        ${format(weekInterval[weekInterval.length - 1], 'LLLL yyyy', {
          locale: ru
        })}`
    }

    if (firstMonth !== lastMonth) {
      return `${format(weekInterval[0], 'LLLL', { locale: ru })} -
        ${format(weekInterval[weekInterval.length - 1], 'LLLL yyyy', {
          locale: ru
        })}`
    }

    return format(weekInterval[0], 'LLLL yyyy', { locale: ru })
  }

  const dataInterval = isMonth ? monthInterval : weekInterval

  return (
    <div className={css.picker}>
      <div className={css.picker__header}>
        <Label className={css.picker__title}>
          {isMonth
            ? format(monthInterval[15], 'LLLL yyyy', { locale: ru })
            : renderMonth()}
        </Label>
        <Button size="icon-sm" variant="tertiary" onClick={prevWeek}>
          <Icon name="arrowLeft" size={20} />
        </Button>
        <Button size="icon-sm" variant="tertiary" onClick={nextWeek}>
          <Icon name="arrowRight" size={20} />
        </Button>
      </div>
      <div className={css.picker__body}>
        <div className={css.picker__date}>
          {dataInterval.slice(0, 7).map((date) => (
            <span key={date.getTime()}>
              {format(date, 'iiiiii', { locale: ru })}
            </span>
          ))}
        </div>
        <div className={css.picker__week}>
          {dataInterval.map((date) => {
            const isDisabledByHallExceptions = closeHallExceptions?.some(
              (e) => {
                const start = parseISO(e.start_date)
                const end = parseISO(e.end_date)
                return isAfter(date, start) && isBefore(date, end)
              }
            )

            const isEnabledByHallExceptions = openHallExceptions?.some((e) => {
              const start = parseISO(e.start_date)
              const end = parseISO(e.end_date)
              return (
                (isAfter(date, start) && isBefore(date, end)) ||
                isSameDay(date, start) ||
                isSameDay(date, end)
              )
            })

            return (
              <div key={date.getTime()} className={css.picker__week__item}>
                <Button
                  size="icon-sm"
                  className={cx(css.picker__day, {
                    [css.picker__day_active]:
                      selectedDate && isEqual(date, startOfDay(selectedDate))
                  })}
                  disabled={
                    (isBefore(date, startOfDay(nowWithTimezone())) ||
                      isInProgress ||
                      (!isSameMonth(date, monthInterval[15]) && isMonth) ||
                      isDisabledByHallExceptions) &&
                    !isEnabledByHallExceptions
                  }
                  onClick={() => selectDateHandler(date)}
                >
                  {format(date, 'd')}
                </Button>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
