export const SESSION_TAG = 'SESSION_TAG'
export const GUEST_TAG = 'GUEST_TAG'
export const GUESTS_TAG = 'GUESTS_TAG'
export const RESERVATION_TAG = 'RESERVATION_TAG'
export const RESERVATIONS_TAG = 'RESERVATIONS_TAG'
export const MAP_TAG = 'MAP_TAG'
export const MAP_TIMELINE_TAG = 'MAP_TIMELINE_TAG'
export const HALLS_TAG = 'HALLS_TAG'
export const MAILINGS_TAG = 'MAILINGS_TAG'
export const MAILING_TAG = 'MAILING_TAG'
export const PROFILE_DATA_TAG = 'PROFILE_DATA_TAG'

export const authTagTypes = {
  SESSION_TAG: 'SESSION_TAG',
  PROFILE_DATA_TAG: 'PROFILE_DATA_TAG'
} as const

export const tagTypes = {
  GUEST_TAG: 'GUEST_TAG',
  GUESTS_TAG: 'GUESTS_TAG',
  RESERVATION_TAG: 'RESERVATION_TAG',
  RESERVATIONS_TAG: 'RESERVATIONS_TAG',
  INPROGRESS_RESERVATIONS_TAG: 'INPROGRESS_RESERVATIONS_TAG',
  MAP_TAG: 'MAP_TAG',
  MAP_TIMELINE_TAG: 'MAP_TIMELINE_TAG',
  HALLS_TAG: 'HALLS_TAG',
  MAILINGS_TAG: 'MAILINGS_TAG',
  MAILING_TAG: 'MAILING_TAG',
  GUEST_COMMENTS_TAG: 'GUEST_COMMENTS_TAG',
  RESERVATION_COMMENTS_TAG: 'RESERVATION_COMMENTS_TAG',
  RESERVATION_TAGS_TAG: 'RESERVATION_TAGS_TAG',
  SCHEDULE_EXCEPTIONS_TAG: 'SCHEDULE_EXCEPTIONS_TAG'
} as const
