import { useEffect, useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'
import { cx } from 'class-variance-authority'
import { add, format, isAfter, isBefore, isEqual, parseISO } from 'date-fns'

import { selectSelectedHall } from '@/entities/halls'
import {
  generateNewIntervals,
  selectAllIntervals,
  selectCurDayInterval,
  selectCurDayIntervalIdx,
  setCurDayIntervalIdx
} from '@/entities/reservation-time-picker'
import { useScheduleExceptionsQuery } from '@/entities/schedule'
import { selectSelectedAddressId } from '@/entities/session'
import { nowWithTimezone } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, Icon, Label } from '@/shared/ui'

import css from './TimePicker.module.css'

type TimePickerProps = {
  workingHoursStart: Date
  workingHoursEnd: Date
  reservationDate?: Date
  reservationStart?: Date
  selectTimeHandler: (value: Date) => void
}

export function TimePicker({
  workingHoursStart,
  workingHoursEnd,
  reservationDate,
  reservationStart,
  selectTimeHandler
}: TimePickerProps) {
  const [isNowActive, setIsNowActive] = useState(false)
  const dispatch = useAppDispatch()
  const curDayIntervalIdx = useAppSelector(selectCurDayIntervalIdx)
  const curDayInterval = useAppSelector(selectCurDayInterval)
  const allIntervals = useAppSelector(selectAllIntervals)
  const addressId = useAppSelector(selectSelectedAddressId)
  const hallId = useAppSelector(selectSelectedHall)
  const now = nowWithTimezone()

  const { data: exceptions } = useScheduleExceptionsQuery(
    addressId ? { addressId } : skipToken
  )

  const hallExceptions = exceptions?.filter(
    (e) => hallId && e.halls.includes(hallId)
  )

  const closeHallExceptions = hallExceptions?.filter((e) => e.type === 'close')
  const openHallExceptions = hallExceptions?.filter(
    (e) => e.type === 'extension'
  )

  useEffect(() => {
    if (reservationDate) dispatch(generateNewIntervals(reservationDate))
  }, [reservationDate])

  const dayIntervalAddHandler = () => {
    dispatch(setCurDayIntervalIdx(curDayIntervalIdx + 1))
  }

  const dayIntervalSubHandler = () => {
    dispatch(setCurDayIntervalIdx(curDayIntervalIdx - 1))
  }

  return (
    <div className={css.picker}>
      <div className={css.picker__header}>
        <Label className={css.picker__title}>Время старта</Label>
        <Button
          size="icon-sm"
          variant="tertiary"
          onClick={dayIntervalSubHandler}
          disabled={curDayIntervalIdx === 0}
        >
          <Icon name="arrowLeft" size={20} />
        </Button>
        <Button
          size="icon-sm"
          variant="tertiary"
          onClick={dayIntervalAddHandler}
          disabled={curDayIntervalIdx === allIntervals.length - 1}
        >
          <Icon name="arrowRight" size={20} />
        </Button>
      </div>
      <div className={css.picker__options}>
        {curDayInterval.hours.map((hour) => {
          const isDisabledByHallExceptions = closeHallExceptions?.some((e) => {
            const start = parseISO(e.start_date)
            const end = parseISO(e.end_date)
            return isAfter(hour, start) && isBefore(hour, end)
          })

          const isEnabledByHallExceptions = openHallExceptions?.some((e) => {
            const start = parseISO(e.start_date)
            const end = parseISO(e.end_date)
            return isAfter(hour, start) && isBefore(hour, end)
          })

          const isDisabled =
            isBefore(hour, now) ||
            isBefore(hour, workingHoursStart) ||
            (isAfter(hour, workingHoursEnd) &&
              isBefore(hour, add(workingHoursStart, { days: 1 })))

          const isActive = reservationStart && isEqual(hour, reservationStart)

          return (
            <Button
              key={hour.toISOString()}
              className={cx(css.picker__button, {
                [css.picker__button_active]: isActive && !isNowActive
              })}
              variant="tertiary"
              onClick={() => {
                selectTimeHandler(hour)
                setIsNowActive(false)
              }}
              disabled={
                (isDisabled || isDisabledByHallExceptions) &&
                !isEnabledByHallExceptions
              }
            >
              {format(hour, 'HH:mm')}
            </Button>
          )
        })}
        <Button
          className={cx(css.picker__button, css.picker__button_now, {
            [css.picker__button_active]: isNowActive
          })}
          variant="tertiary"
          onClick={() => {
            selectTimeHandler(nowWithTimezone())
            setIsNowActive(true)
          }}
        >
          Сейчас
        </Button>
      </div>
    </div>
  )
}
